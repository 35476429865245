export const API_TAG = '/api/' as const

export type Environment = {
  env: string
  assets: string
  production: boolean
  auth: {
    domain: string
    clientId: string
    audience: string
    apiUri: string
    appUri: string
    errorPath: string
  }
  apis: {
    identity: string
    management: `${string}${typeof API_TAG}`
  }
  apiKey: {
    management: string
  }
  loginUrl: string
  redirectUrl: string
  pendo: {
    key: string
  }
  appInsights: {
    connectionString: string
  }
  httpInterceptorList: `${string}*`[]
  launchDarkly: {
    clientSideId: string
  }
  pspdfkit: {
    licenseKey: string
  }
}
