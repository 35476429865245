import { API_TAG, Environment } from './environment.type'

export const environment: Environment = {
  production: false,
  httpInterceptorList: [`https://api.navix.io/${API_TAG}*`],
  env: 'PROD',
  assets: 'https://fpasaprodue01sa.blob.core.windows.net/' + 'assets',
  auth: {
    domain: 'auth.navix.io',
    audience: 'https://navix/api',
    clientId: 'R7gvi1RLR9McPxRlMPJsXkUIpCsa3mEJ',
    apiUri: 'https://fpa-authapi-prod-ue-01-as.azurewebsites.net',
    appUri: 'https://fpa-authapi-prod-ue-01-as.azurewebsites.net',
    errorPath: '/error'
  },
  apis: {
    identity: 'https://api.navix.io/',
    management: `https://api.navix.io/${API_TAG}`,
  },
  apiKey: {
    management: 'ba64975e168442b895901435cb33a34f'
  },
  loginUrl: 'https://app.navix.io' + '/login',
  redirectUrl: 'https://app.navix.io' + '/dashboard',
  pendo: {
    key: 'c883b44e-1006-4016-5805-b6364c43586b'
  },
  appInsights: {
    connectionString: 'InstrumentationKey=71ee8e60-7af8-4408-92fc-2b0d483e66d7;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/;ApplicationId=eef1acd9-3324-4db3-badb-87f433ed1c66'
  },
  launchDarkly: {
    clientSideId: '61f9466164ac6a17bae29c13'
  },
  pspdfkit: {
    licenseKey: 'QO8Tdnwze_aIB0kK5lnpS0gRDEZ8OZ80zTYbWj-zMe7grd-RRWvmfN-ePHPvR0ks4N_3DB-Fe-wm1MzC2eVTSndFwOZVO8s3Wqay3rRs6w1yACZeo_SFsYP5otKC8hu8l4rltxAr7_uFL5E7LgVYE_mJz6R1kOCzzeRamLbY4DkkcZcWPJ98iJbrEy3QY4Hafr0_6nYuTT0zdZK3LgeaHp58tOymEjhy__MYFfAWGKlQZAPWnB5ise45ZVrcQFhfJrv8td_dHYRn1kxSZceqNhHMEn8FVqzjQX8BLwjPjkcUI8Qd4kWVIZzg9ls9MEvLHzRqQKrh1gpGdzzQw1sX6xSASxoy2BjSpdPVkK-OFzEV34iEnaoRLwsZox_3ryCPU56j4NteyvjCzvhYNwSEjGpj2jUkJh9wzkoY2Xtrtx3WqgKxwONF2KKzyavRR1tRP9glE3iKHRmnTgE2-F2b9-9_oUkwJiz4CKw1bYwzSoWLpD4oueJq6malgLWt7r8jvwfq3l7XMtCKnTZM5_xTSmGGSxRHpWUzPssgxbwn0h9g8CPqoq3zJywAYq8gKtW4'
  }
}
